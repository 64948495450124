<template>
  <section id="form-wizard-ads">
    <b-row class="mb-5">
      <b-col md="10">
        <h1>{{ $t('adsDisplay.ads') }}</h1>
        <h5 class="text-primary">
          {{ $t('adsDisplay.adsAdd') }}
        </h5>
      </b-col>
      <b-col class="d-flex justify-content-end mr-1">
        <div>
          <b-button
            variant="danger"
            @click="routeToEditView()"
          >
            {{ $t("dataGeneric.goBack") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-overlay
      variant="transparent"
      :show="show"
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        :finish-button-text="$t('dataGeneric.create')"
        :back-button-text="$t('dataGeneric.goBack')"
        :next-button-text="$t('next')"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content
          :title="$t('code.info')"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  {{ $t("code.info") }}
                </h5>
                <small class="text-muted">
                  {{ $t("code.introd") }}
                </small>
              </b-col>

              <b-col md="6">
                <validation-provider
                  v-slot="validationContext"
                  name="name"
                  rules="required|min:3"
                >
                  <b-form-group :label="$t('code.introd') + ' *'">
                    <b-form-input
                      id="name"
                      v-model="name"
                      autocomplete="new-password"
                      :state="validationContext.errors.length > 0 ? false : null"
                      :placeholder="$t('firstName')"
                    />
                    <small class="text-danger">{{ fix(validationContext) }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('dataGeneric.description')">
                  <b-form-textarea
                    v-model="description"
                    :placeholder="$t('dataGeneric.description')"
                    rows="3"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content
          :title="$t('Configuration')"
          :before-change="validationFormConfig"
        >
          <validation-observer
            ref="ConfigRules"
            tag="form"
          >
            <b-form @submit.prevent="checkSlots">
              <b-card class="h-100">
                <b-row>
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="rootCategoryName"
                      rules="required"
                    >
                      <b-form-group
                        :label="$t('adsDisplay.category')"
                        :invalid-feedback="$t('reCat')"
                        :state="errors.length > 0 ? false : null"
                      >
                        <div class="d-flex">
                          <b-form-input
                            id="rootCategoryName"
                            v-model="rootCategoryName"
                            disabled
                            :placeholder="$t('adsDisplay.category-description')"
                            :state="errors.length > 0 ? false : null"
                          />
                          <b-button
                            class="ml-2"
                            variant="primary"
                            @click.stop.prevent="showModal('cat')"
                          >
                            {{ $t('Select') }}
                          </b-button>
                        </div>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('adsDisplay.adsPosition')">
                      <validation-provider
                        v-slot="{ errors }"
                        name="positionCategory"
                        rules="required"
                      >
                        <b-form-select
                          v-model="positionCategory"
                          :options="positionCategoryOptions"
                        />
                        <small class="text-primary">
                          {{ $t("adsDisplay.adsPosition-description") }}</small><br>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- MODAL PARA SELECCIONAR CATEGORÍA -->
                  <b-col>
                    <b-modal
                      ref="CatModal"
                      size="xl"
                      hide-footer
                      :title="$t('modal.category')"
                    >
                      <div class="d-block text-center">
                        <category-selector
                          :root-categories="true"
                          :columns="[
                            { key: 'image', label: $t('contents.category') },
                            { key: 'name', label: $t('firstName') },
                            { key: 'type', label: $t('type') },
                          ]"
                          @confirm-selection="getCategory"
                        />
                      </div>
                      <b-button
                        class="mt-3"
                        variant="outline-danger"
                        block
                        @click.stop.prevent="hideModal('cat')"
                      >
                        {{ $t("dataGeneric.close") }}
                      </b-button>
                    </b-modal>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="3">
                    <b-form-group :label="$t('adsDisplay.mobileType')">
                      <b-form-checkbox
                        v-model="isMobileType"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group :label="$t('adsDisplay.webType')">
                      <b-form-checkbox
                        v-model="isWebType"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <small
                  v-if="!isValid"
                  class="text-danger"
                >{{ $t('adsDisplay.atLeatsSelected') }}</small>
              </b-card>
              <b-col
                v-if="isMobileType"
                cols="12"
              >
                <hr class="my-4">
              </b-col>
              <b-row v-if="isMobileType">
                <b-col
                  cols="12"
                  class="ml-2"
                >
                  <h5 class="mb-0">
                    {{ $t("adsDisplay.adsMobileConfig") }}
                  </h5>
                  <small class="text-muted">
                    {{ $t("adsDisplay.adsMobileConfigSub") }}
                  </small>
                </b-col>
                <b-col
                  :cols="12"
                  :md="12"
                >
                  <b-card
                    class="h-100"
                  >
                    <!-- Mobile ID -->
                    <b-row>
                      <b-col cols="6">
                        <validation-provider
                          v-slot="validationContext"
                          name="adUnitId"
                          rules="checkIdRule"
                        >
                          <b-form-group :label="$t('adsDisplay.adsUnitId')">
                            <b-form-input
                              id="adUnitId"
                              v-model="adUnitId"
                              autocomplete="new-password"
                              :state="validationContext.errors.length > 0 ? false : null"
                              :placeholder="$t('adsDisplay.adsUnitId-description')"
                            />
                            <small class="text-danger">{{ fix(validationContext) }}</small>
                            <small
                              v-if="validationContext.errors.length <= 0"
                              class="text-primary"
                            >{{ $t("adsDisplay.adsUnitId-description") }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col md="6">
                        <b-form-group :label="$t('adsDisplay.adsLocation')">
                          <validation-provider
                            v-slot="validationContext"
                            name="location"
                            rules="required"
                          >
                            <b-form-select
                              v-model="location"
                              :disabled="true"
                              :options="locationOptions"
                            />
                            <small class="text-primary">{{ $t("adsDisplay.adsLocation-description") }}</small><br>
                            <small class="text-danger">{{ inputDecimalNumber(validationContext) }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
              <b-col
                v-if="isWebType"
                cols="12"
              >
                <hr class="my-4">
              </b-col>
              <b-row v-if="isWebType">
                <b-col
                  cols="12"
                  class="ml-2"
                >
                  <h5 class="mb-0">
                    {{ $t("adsDisplay.webConfigTitle") }}
                  </h5>
                  <small class="text-muted">
                    {{ $t("adsDisplay.webConfigSub") }}
                  </small>
                </b-col>
                <b-col
                  :cols="12"
                  :md="12"
                >
                  <b-card
                    class="h-100"
                    :header="$t('adsDisplay.slotsModalSub')"
                    header-class="font-weight-bold"
                  >
                    <!-- Search Input -->
                    <b-row class="mb-2">
                      <b-col cols="9">
                        <b-form-input
                          v-model="searchInput"
                          :placeholder="$t('adsDisplay.searchSlot')"
                          autofocus
                          autocomplete="off"
                        />
                      </b-col>
                      <b-col>
                        <b-button
                          class="ml-2"
                          variant="primary"
                          @click.stop.prevent="showModal('slot')"
                        >
                          {{ $t('adsDisplay.slotCreate') }}
                        </b-button>
                      </b-col>
                    </b-row>

                    <!-- Slot Table -->
                    <b-table
                      id="slot-table"
                      ref="slotTable"
                      :items="dataArray"
                      responsive
                      :fields="columns"
                      class="mb-1 tableHeight"
                      show-empty
                      :empty-text="$t('dataNotFound')"
                      selectable
                      :per-page="15"
                      :current-page="currentPage"
                      @row-selected="onRowSelected"
                    >
                      <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                          <span
                            class="text-success"
                            aria-hidden="true"
                          >&check;</span>
                        </template>
                        <template v-else>
                          <span aria-hidden="true">&nbsp;</span>
                        </template>
                      </template>
                      <template #cell(name)="data">
                        <div class="d-flex align-items-center">
                          {{ data.item.node.slotId }}
                        </div>
                      </template>
                      <template #cell(displayAd)="data">
                        <div
                          v-if="data.item.node.displayAd"
                          class="d-flex align-items-center"
                        >
                          <span
                            class="mr-50"
                          >
                            {{ data.item.node.displayAd.name }}
                          </span>
                          <b-link
                            style="color:light; text-decoration: underline;"
                            class="cursor-pointer"
                            :href="'/displayAdvertising/ads/edit/' + data.item.node.displayAd.id"
                            target="_blank"
                          >
                            <feather-icon icon="ExternalLinkIcon" />
                          </b-link>
                        </div>
                      </template>
                      <template #cell(defaultSize)="data">
                        <div class="d-flex align-items-center">
                          {{ data.item.node.defaultSize }}
                        </div>
                      </template>
                      <template #cell(linkedSizeMapping)="data">
                        <div class="d-flex align-items-center">
                          {{ data.item.node.sizeMapping.name }}
                        </div>
                      </template>
                      <template #cell(actions)="data">
                        <div class="text-nowrap">
                          <b-button
                            class="mr-1"
                            variant="outline-success"
                            size="sm"
                            @click="editSlot(data.item.node)"
                          >
                            <feather-icon icon="EditIcon" />
                          </b-button>
                          <b-button
                            variant="outline-danger"
                            size="sm"
                            @click="deleteSlot(data.item.node.id)"
                          >
                            <feather-icon icon="Trash2Icon" />
                          </b-button>
                        </div>
                      </template>
                    </b-table>
                    <small
                      v-if="isEmpty(slotsSelected) && isWebType"
                      class="text-danger"
                    >{{ $t("adsDisplay.atLeatsOneSlot") }}</small>
                    <!-- Pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="dataArray ? dataArray.length : 0"
                      :per-page="15"
                      align="center"
                      size="sm"
                      class="mt-2"
                    />
                  </b-card>
                </b-col>
                <b-col md="6">
                  <div class="d-block text-center">
                    <slot-creator-modal
                      :fetched-slot="slotToEdit"
                      @confirm-selection="onConfirmSelection"
                      @update-slots="onConfirmSelection"
                      @modal-closed="hideModal('slot')"
                    />
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </b-overlay>
  </section>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  showToast, messageError, inputDecimalNumber, isEmpty,
} from '@/store/functions'

import {
  BFormTextarea,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BOverlay,
  BFormSelect,
  BTable,
  BPagination,
  BCard,
  BLink,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import axios from '@axios'

import { getUserData } from '@/auth/utils'
import CategorySelector from '@/views/common/CategorySelector.vue'
import SlotCreatorModal from '@/views/common/SlotCreatorModal.vue'

export default {
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BFormTextarea,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BOverlay,
    BFormSelect,
    BTable,
    BPagination,
    BCard,
    BLink,
    CategorySelector,
    SlotCreatorModal,
  },
  data() {
    return {
      userData: getUserData(),
      inputDecimalNumber,
      required,
      prints: null,
      timeout: null,
      description: '',
      name: null,
      show: false,
      locationOptions: [
        { value: 'TOP', text: this.$t('top') },
        { value: 'MID', text: this.$t('middle') },
        { value: 'BOT', text: this.$t('bottom') },
      ],
      location: 'MID',
      positionCategoryOptions: [
        { value: 'PRE', text: this.$t('adsDisplay.positionPre') },
        { value: 'POS', text: this.$t('adsDisplay.positionPos') },
      ],
      positionCategory: 'PRE',
      adUnitId: '',
      first: true,

      checkResults: [],
      checkRemove: [],
      requiredField: false,
      rootCategory: null,
      rootCategoryName: '',
      columns: [
        { key: 'selected', label: this.$t('adsDisplay.selected') },
        { key: 'name', label: this.$t('adsDisplay.slotId') },
        { key: 'displayAd', label: this.$t('adsDisplay.displayAdSlot') },
        { key: 'defaultSize', label: this.$t('adsDisplay.defaultSize') },
        { key: 'linkedSizeMapping', label: this.$t('adsDisplay.linkedSizeMapping') },
        { key: 'actions', label: this.$t('resources.tableHeader.actions') },

      ],
      searchInput: '',
      dataArray: [],
      currentPage: 1,
      slotsSelected: [],
      table: null,
      tablename: 'slot-table',
      slotToEdit: null,
      isWebType: false,
      isMobileType: false,
    }
  },
  computed: {
    isValid() {
      return this.isWebType || this.isMobileType
    },
  },
  watch: {
    timeout() {
      if (this.timeout.match(/\d+$/) === null) this.timeout = ''
    },
    prints() {
      if (this.prints.match(/^\d+$/) === null) this.prints = ''
    },
    searchInput() {
      this.fetchSlots()
    },
    slotsSelected() {
      this.$refs.ConfigRules.validate()
    },
  },
  mounted() {
    this.fetchSlots()
    extend('checkIdRule', {
      validate: () => this.adUnitId !== '' && this.isMobileType,
      computesRequired: true,
    })
    extend('checkToggles', {
      validate: (value, { otherValue }) => value || otherValue,
      computesRequired: true,
    })
  },
  methods: {
    isEmpty,
    fix(data) {
      const existeClave = Object.keys(data.failedRules)
      if (existeClave.includes('required') || existeClave.includes('checkIdRule')) {
        return this.$t('required')
      }
      if (existeClave.includes('min')) {
        return this.$t('purchases.nameFormThreeCaract')
      }
      return ''
    },
    onRowSelected(items) {
      this.slotsSelected = items
    },
    formSubmitted() {
      const alreadyAssignedSlots = this.slotsSelected.filter(slot => slot.node.displayAd !== null)

      if (isEmpty(alreadyAssignedSlots)) {
        this.createDisplayAds()
        return
      }

      this.$swal({
        title: this.$t('adsDisplay.assignedSlots'),
        text: this.$t('adsDisplay.changeAssignedSlots'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('yNext'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.removeDisplayAdSlots(alreadyAssignedSlots.map(element => element.node))
          }
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.errorCre'), 2, this)
          this.show = false
        })
    },
    createDisplayAds() {
      this.show = true
      const isActive = this.rootCategory.isActive && this.rootCategory.state === 'GRE'
      axios
        .post('', {
          query: `
          mutation{
            createDisplayAds(input:{
              name:"${this.name}",
              client:"${this.userData.profile.client.id}",
              description:"${this.description}",
              campaign:"${this.$route.params.campaign}",
              isActive:${isActive},
              positionCategory: ${this.positionCategory},
              adUnitId:"${this.isMobileType ? this.adUnitId : ''}",
              location: ${this.location},
              category: ${this.rootCategory.id},
              targetMobileActive: ${this.isMobileType},
              targetWebActive: ${this.isWebType},
              }){
            displayAd
            {
              id
              name
            }
          }
          }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.creado'), 1, this)
          this.show = false
          const { id } = res.data.data.createDisplayAds.displayAd
          if (!isEmpty(this.slotsSelected) && this.isWebType) this.assignSlots(this.slotsSelected.map(item => item.node.id), id)
          else this.routeToEditView()
        })
        .catch(error => {
          console.log(error)

          showToast(this.$t('code.errorCre'), 2, this)
          this.show = false
        })
    },
    assignSlots(slots, displayAd) {
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let query = 'mutation {'
      for (let i = 0; i < slots.length; i += 1) {
        query += `
        m${i + 1}: ${'updateSlot'}(id: "${slots[i]}", input:{displayAd:"${displayAd}"}){
          slot{
            id
          }
        }
      `
      }
      query += '}'
      data.append('query', query)
      const { headers } = this
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this).then(r => {
            if (!r) {
              this.routeToEditView()
            }
          }).catch(() => {
            showToast(this.$t('error'), 2, this)
          })

          this.show = false
        })
        .catch(error => {
          this.show = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    removeDisplayAdSlots(assignedSlots) {
      this.show = true
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      let query = 'mutation {'
      for (let i = 0; i < assignedSlots.length; i += 1) {
        query += `
        m${i + 1}: ${'updateSlot'}(id: "${assignedSlots[i].id}", input:{displayAd: null}) {
          slot{
            id
          }
        }
      `
      }
      query += '}'
      data.append('query', query)
      const { headers } = this
      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this).then(r => {
            if (!r) {
              this.createDisplayAds()
            }
          }).catch(() => {
            showToast(this.$t('error'), 2, this)
          })

          this.show = false
        })
        .catch(error => {
          this.show = false
          console.log(error)

          showToast(this.$t('error'), 2, this)
        })
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormConfig() {
      return new Promise((resolve, reject) => {
        this.$refs.ConfigRules.validate().then(success => {
          if (success && this.isValid && (!this.isWebType || !this.isEmpty(this.slotsSelected))) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    getIdArray(array) {
      const res = []
      for (let i = 0; i < array.length; i += 1) {
        res.push(array[i].node.id)
      }
      return res
    },
    routeToEditView() {
      const { campaign } = this.$route.params
      this.$router.push({
        name: 'display-ads-list',
        params: { campaign },
      })
    },
    saveRange(node = null) {
      this.positionCategory = node
    },
    getCategory(item) {
      this.rootCategory = item.node
      this.rootCategoryName = this.rootCategory.name
      this.hideModal('cat')
    },
    setSlot(items) {
      if (items.length > 0) {
        items.forEach(item => {
          const matchingItem = this.dataArray.find(data => data.node.id === item.id)
          if (matchingItem) {
            this.$refs.slotTable?.selectRow(this.dataArray.indexOf(matchingItem))
          }
        })
      }
      this.hideModal('slot')
    },
    async fetchSlots() {
      try {
        const res = await axios.post('', {
          query: `
        query {
          allSlots(client:"${this.userData.profile.client.id}", slotId: "${this.searchInput}", orderBy:"slotId") {
            edges {
              node {
                id
                slotId
                defaultSize
                displayAd {
                  id
                  name
                }
                sizeMapping {
                  id
                  name
                  mappings
                }
              }
            }
          }
        }
      `,
        })

        messageError(res, this)
        this.dataArray = res.data.data.allSlots.edges
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    showModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.show()
          break
        case 'slot':
          this.$bvModal.show('slot-creator-modal')
          break
        default:

          break
      }
    },
    hideModal(type) {
      switch (type) {
        case 'cat':
          this.$refs.CatModal.hide()
          break
        case 'slot':
          this.slotToEdit = null
          this.$bvModal.hide('slot-creator-modal')
          break
        default:
          break
      }
    },
    deleteSlot(slotId) {
      this.slotsSelected = this.slotsSelected.filter(item => item.node.id !== slotId)

      axios
        .post('', {
          query: `
            mutation{
              deleteSlot(id: "${slotId}") {
                found
                deletedId
              }
            }
          `,
        })
        .then(response => {
          this.onConfirmSelection()
          if (response.data.data.deleteSlot.found)showToast(this.$t('adsDisplay.slotDeleted'), 1, this)
          else showToast(this.$t('adsDisplay.slotDeletedError'), 2, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.slotDeletedError'), 2, this)
        })
    },
    editSlot(slot) {
      this.slotToEdit = slot
      this.showModal('slot')
    },
    async onConfirmSelection(slotCreated = null) {
      try {
        let currentSelectedSlots = []
        if (!isEmpty(this.slotsSelected)) {
          currentSelectedSlots = [...this.slotsSelected.map(element => element.node)]
        }
        await this.fetchSlots()
        if (slotCreated)currentSelectedSlots.push(slotCreated)

        this.setSlot(currentSelectedSlots)
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

#form-wizard-ads .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

#form-wizard-ads .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
}

#form-wizard-ads .modal {
  overflow-y: auto;
}

/*
Full screen Modal
*/
#form-wizard-ads .fullscreen-modal .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

@media (min-width: 768px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 750px;
  }
}

@media (min-width: 992px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  #form-wizard-ads .fullscreen-modal .modal-dialog {
    width: 1170px;
  }

  .range-slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
  }

  .range-input {
    width: 100%;
    margin: 20px 0;
    -webkit-appearance: none;
    background: transparent;
  }

  .range-input::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: #7367f0;
    border-radius: 5px;
  }

  .range-input::-webkit-slider-thumb {
    border: 1px solid #000000;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  .range-input:focus::-webkit-slider-runnable-track {
    background: #7367f0;
  }

  .range-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;
  }

  hr {
    border-color: #d3d3d3; /* Light gray */
    border-width: 1px;
    width: 100%;
  }

}
</style>
