<template>
  <section>
    <b-overlay
      variant="transparent"
      :show="overlay"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mt-1 mr-1">
        <b-col style="text-align: justify;">
          <h1>{{ $t("Categories") }}</h1>
          <h5 class="text-primary">
            {{ $t("contents.selectCategory") }}
          </h5>
        </b-col>

        <b-col class="d-flex justify-content-end">
          <span
            v-if="limitContent>0"
            class="align-items-center mr-2 mt-1"
          >
            <b-badge
              v-b-tooltip.hover.top="$t('messageFC',{limitContent})"
              pill
              variant="light-warning"
            >
              {{ $t('uploadCont') }}: <strong> {{ totalLimit }}</strong>
            </b-badge>
          </span>
          <b-button
            style="height: fit-content;"
            variant="success"
            :disabled="disabled"
            @click="confirmSelection"
          >
            {{ $t("select") }}
          </b-button>
        </b-col>
      </b-row>

      <b-row class="align-items-center mb-3 mt-3">
        <b-col cols="6">
          <b-form-input
            v-model="searchInput"
            :placeholder="emptySearch"
            autofocus
            autocomplete="off"
            @change="searching()"
          />
        </b-col>
        <b-col md="1">
          <b-button
            variant="info"
            @click="searching()"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </b-col>
        <b-col
          md="1"
          class="ml-3"
        >
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            size="lg"
            class="pt-1"
            @input="allCategory"
          />
        </b-col>
      </b-row>

      <b-row style="height:621px">
        <b-col
          style="height:603px"
          class="scroll-list list-group list-group-flush mb-1"
        >
          <b-overlay
            variant="transparent"
            :show="showList"
            :opacity="1.00"
            :blur="'2px'"
            rounded="lg"
            tag="ul"
          >
            <b-list-group
              horizontal
              class="ml-2"
            >
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('category.card') }}
                </h4>
              </b-list-group-item>
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('idCategory') }}
                </h4>
              </b-list-group-item>
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('category.name') }}
                </h4>
              </b-list-group-item>
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('category.parent') }}
                </h4>
              </b-list-group-item>
              <b-list-group-item
                tag="li"
                class="noBorder"
              >
                <h4 class="m-1">
                  {{ $t('code.type') }}
                </h4>
              </b-list-group-item>
            </b-list-group>
            <b-list-group-item
              v-for="res in data"
              v-show="data.length != 0"
              :id="res.node.id"
              :key="res.node.id"
              :active="checkActive(res.node.id)"
              class="ml-2"
              tag="li"
              @click="
                clickRow(res)"
              @dblclick="confirmSelection"
            >
              <b-row class="align-items-center">
                <b-col>
                  <b-img
                    id="image"
                    style="object-fit: contain;"
                    rounded="lg"
                    :src="buildImageUrlBucket(res)"
                  />
                </b-col>
                <b-col>
                  <b-card-text class="mb-0 font-weight-bold">
                    <strong>
                      <b-link
                        style="color:white; text-decoration: underline;"
                        class="cursor-pointer"
                        :href="'/contents/categories/' + res.node.id"
                        target="_blank"
                      >

                        {{ res.node.id }}
                      </b-link>

                    </strong>
                  </b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0 font-weight-bold">
                    <strong>{{ res.node.name }}</strong>
                  </b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0 font-weight-bold ">
                    {{ isEmpty(res.node.parent) ? $t('contents.start') : res.node.parent.name }}
                  </b-card-text>
                </b-col>
                <b-col>
                  <b-card-text class="mb-0 font-weight-bold">
                    {{ $t(categoryTypes[res.node.type]) }}
                  </b-card-text>
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item
              v-if="data.length == 0 && searchInput.length != 0"
              class="ml-2"
              disabled
              tag="li"
            >
              <h5
                class="m-2"
                style="text-align: center"
              >
                {{ $t('subscriptions.notFound') }}
              </h5>
            </b-list-group-item>
          </b-overlay>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BButton,
  BListGroup,
  BCardText,
  BImg,
  VBTooltip,
  BBadge,
  BOverlay,
  BPagination,
  BLink,
  BCol,
  BRow,
  BFormInput,
  BListGroupItem,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { messageError, isEmpty } from '@/store/functions'
import axios from '@axios'
import configCategoryType from '@core/utils/categoryType'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BCol,
    BRow,
    BBadge,
    BImg,
    BCardText,
    BOverlay,
    BLink,
    BFormInput,
    BPagination,
    BButton,
    BListGroup,
    BListGroupItem,
  },

  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    limitContent: { type: Number, default: null },
    multi: { type: Boolean, default: null },
    sponsor: { type: Boolean, default: null },
    type: { type: Boolean, default: null }, // ¿Categoría final?
    isPremium: { type: Boolean, default: false }, // Categorías de pago
    especial: { type: Boolean, default: false }, // ¿Oculta especiales? incluido enlace
    enlace: { type: Boolean, default: false }, // Oculta especiales, muestra enlace
    columns: { type: Array, default: () => [] },
    excludeIds: { type: String, default: '' }, // No muestra las categorías con estos ids
    menuIn: { type: String, default: null }, // Busca solo por un tipo: POD,DIR...
    menuEx: { type: String, default: null }, // Excluye el tipo que se le indique: POD,DIR...
    serYpro: { type: Boolean, default: false }, // ¿Oculta serie y programa?
    rootCategories: { type: Boolean, default: null }, // True: devuelve ÚNICAMENTE las categorías root. False: NO devuelve las categorías root
  },

  data() {
    return {
      searchInput: '',
      rows: null,
      rowSelected: null,
      categoryTypes: configCategoryType(true, false, false, false),
      overlay: false,
      perPage: 15,
      currentPage: 1,
      emptySearch: this.$t('slider.searchThreeCaracCat'),
      id: null,
      data: [],
      headers: {},
      userData: getUserData(),
      name: '',
      image: '',
      showList: false,
      disabled: true,
      categorySelected: [],
      selectedMulti: [],
      objectLimit: [],
      totalLimit: 0,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.allCategory()
  },

  methods: {
    searching() {
      if (this.searchInput.length >= 3) {
        this.currentPage = 1
        this.allCategory()
      }
      if (this.searchInput.length < 3) {
        this.data = []
      }
      if (this.searchInput.length === 0) {
        this.allCategory()
      }
    },
    isEmpty,
    allCategory() {
      const { headers } = this
      this.overlay = true
      const userData = getUserData()
      axios
        .post('', {
          query: `
            query{  
            allCategories(
              first:${this.perPage},
              offset:${this.perPage * (this.currentPage - 1)},
              client:"${userData.profile.client.id}",
              search:"${this.searchInput}",
              especial:${this.especial},
              isPremium:${this.isPremium},
              serYpro:${this.serYpro},
              enlace:${this.enlace},
              excludeIds:"${this.excludeIds.replace(/,($|\s+)/, '$1')}"
              ${this.type != null ? `,isFinal:${this.type}` : ''}
              ${this.sponsor ? `,filter_Sponsor:"${userData.id}"` : ''}
              ${this.menuIn != null ? `,menuIn:"${this.menuIn}"` : ''}
              ${this.menuEx != null ? `,menuEx:"${this.menuEx}"` : ''}
              ${this.rootCategories != null ? `,parent_Isnull:${this.rootCategories}` : ''}
              ){
              pageInfo{
                hasPreviousPage
                hasNextPage
              }
              totalCount
              edgeCount
              edges{
                node{
                  id
                  name  
                  totalContent                
                  imageUrl
                  isFinal
                  type
                  orderType
                  order
                  isActive
                  state
                  parent {
                    id
                    name
                  }
                  categoryContent(orderBy:"order,-created_at") {
                    edges {
                      node {
                        id
                        name
                        order
                        emisionDate
                      }
                    }
                  }
                  childCategories {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                  parent {
                    id
                    name
                  }

                }
              }
            }
          }
        `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          this.data = res.data.data.allCategories.edges
          this.rows = res.data.data.allCategories.totalCount
          if (this.data.length === 0) { this.emptySearch = this.$t('slider.searchThreeCaracCat') }

          this.overlay = false
        }).catch(error => {
          console.log(error)
        })
    },

    checkActive(id) {
      if (this.multi) {
        if (this.selectedMulti.includes(id)) {
          return true
        }
        return false
      }
      return this.id === id
    },
    clickRow(valueSelected) {
      this.categorySelected = valueSelected.node

      this.id = valueSelected.node.id
      this.name = valueSelected.node.name
      const totalContent = parseInt(valueSelected.node.totalContent, 10)
      this.image = valueSelected.node.imageUrl.length !== 0 ? valueSelected.node.imageUrl
        : noCover

      if (this.multi) {
        document.getElementById(this.id).classList.toggle('active')
        if (this.selectedMulti.includes(this.id)) {
          this.selectedMulti = this.selectedMulti.filter(item => item !== this.id)
          this.totalLimit -= totalContent
        } else {
          this.selectedMulti.push(this.id)
          this.totalLimit += totalContent
        }
        if (this.selectedMulti.length > 0) {
          this.disabled = false
        } else {
          this.disabled = true
        }
      } else if (this.id) {
        this.disabled = false
      } else {
        this.disabled = true
      }
      if (this.limitContent > 0) {
        if (this.totalLimit > this.limitContent) {
          this.disabled = true
        } else {
          this.disabled = false
        }
      }
    },

    buildImageUrlBucket(data) {
      if (data.node.imageUrl) {
        return data.node.imageUrl
      }
      return noCover
    },

    confirmSelection() {
      const data = []
      data.node = this.categorySelected
      if (this.multi) this.$emit('data', this.selectedMulti)
      else this.$emit('confirm-selection', data)
    },
  },
}
</script>

<style scoped>
.scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 333px;
  margin-right: 20px;
}

.noBorder {
  border-bottom: 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

#image {
  width: 10rem;
  height: 5.5rem;
}
</style>
