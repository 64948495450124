<template>
  <form
    ref="form"
    @submit.stop.prevent="handleSubmit"
  >
    <b-modal
      id="slot-creator-modal"
      :ok-title="!editSlot ? $t('adsDisplay.slotAssign') : $t('dataGeneric.save')"
      :cancel-title="$t('dataGeneric.cancel')"
      centered
      size="lg"
      static
      :title="$t('adsDisplay.slotsModalTitle')"
      @show="onModalOpened()"
      @hidden="onModalClosed()"
      @ok="confirmSelection"
    >
      <b-row>
        <b-col
          :cols="12"
          md="12"
          class="pl-2"
        >
          <b-card
            class="h-100"
            :header="editSlot == null ? $t('adsDisplay.slotCreate') : $t('adsDisplay.slotEdit')"
            header-class="justify-content-center font-weight-bold"
          >
            <!-- Slot Details -->
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('adsDisplay.slotId') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="slotIdState"
                >
                  <b-form-input
                    v-model="slotIdInput"
                    placeholder="Enter Slot ID"
                    :state="slotIdState"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('adsDisplay.defaultSize') + ' *'"
                  :invalid-feedback="$t('jsonInvalid')"
                  :state="sizeState"
                >
                  <b-form-input
                    v-model="defaultSizeInput"
                    placeholder="[970, 250]"
                    :state="sizeState"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Size Mapping Section -->
            <b-row v-if="!isCreatingSizeMapping && !editSizeMapping">
              <b-col>
                <b-form-group
                  :label="$t('adsDisplay.sizeMappingSelect') + ' *'"
                  :invalid-feedback="$t('required')"
                  :state="sizeMappingState"
                >
                  <b-list-group>
                    <b-list-group-item
                      v-for="sizeMapping in sizeMappingOptions"
                      :key="sizeMapping.node.id"
                      class="d-flex justify-content-between align-items-center"
                      :active="checkActive(sizeMapping.node.id)"
                      @click="clickRow(sizeMapping)"
                    >
                      <div>
                        <b-card-text class="mb-0 font-weight-bold">
                          {{ sizeMapping.node.name }}
                        </b-card-text>
                      </div>
                      <div style="align-items: end;">
                        <b-link
                          class="mr-1"
                          @click="onChangeSizeMapping(sizeMapping.node)"
                        >
                          <feather-icon
                            color="white"
                            icon="EditIcon"
                            size="15"
                          />
                        </b-link>
                        <b-link
                          @click="deleteSizeMapping(sizeMapping.node.id)"
                        >
                          <feather-icon
                            color="white"
                            icon="XIcon"
                            size="15"
                          />
                        </b-link>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Create New Size Mapping -->
            <b-row v-else>
              <b-card
                class="mt-1"
                :header="!editSizeMapping ? $t('adsDisplay.sizeMappingCreate') : $t('adsDisplay.sizeMappingEdit')"
                header-class="justify-content-center font-weight-bold"
                bg-variant="dark"
                style="width: 100%; height: 300px;"
              >
                <b-row>
                  <b-col>
                    <b-form-group
                      :label="$t('name')"
                      :invalid-feedback="$t('required')"
                      :state="sizeMappingNameState"
                    >
                      <b-form-input
                        v-model="sizeMappingNameInput"
                        :state="sizeMappingNameState"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="$t('Mapping')"
                      :invalid-feedback="$t('jsonInvalid')"
                      :state="sizeMappingMappingsState"
                    >
                      <b-form-textarea
                        v-model="sizeMappingMapInput"
                        :state="sizeMappingMappingsState"
                        placeholder="[{&quot;size&quot;: [1170, 200], &quot;slot_sizes&quot;: [[970, 250]]}]"
                        style="height: 150px;"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="text-right">
                    <b-button
                      v-if="editSizeMapping"
                      class="mr-1"
                      variant="outline-light"
                      @click="cancelEdit()"
                    >
                      {{ $t('dataGeneric.cancel') }}
                    </b-button>
                    <b-button
                      variant="primary"
                      @click="!editSizeMapping ? createSizeMapping() : changeSizeMapping()"
                    >
                      {{ !editSizeMapping ? $t('adsDisplay.sizeMappingCreateButton') : $t('dataGeneric.save') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-row>

            <!-- Toggle for Creating Size Mapping -->
            <b-row class="mt-1">
              <b-col>
                <b-form-checkbox
                  v-model="isCreatingSizeMapping"
                  :disabled="editSizeMapping"
                  switch
                >
                  {{ $t('adsDisplay.sizeMappingCreate') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </form>
</template>

<script>
import {
  BModal,
  BCol,
  BRow,
  BFormInput,
  BButton,
  BFormGroup,
  BCard,
  BFormTextarea,
  BFormCheckbox,
  BLink,
  BCardText,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { isEmpty, messageError, showToast } from '@/store/functions'

export default {
  components: {
    BCol,
    BRow,
    BModal,
    BFormInput,
    BButton,
    BFormGroup,
    BCard,
    BFormTextarea,
    BFormCheckbox,
    BLink,
    BCardText,
    BListGroup,
    BListGroupItem,
  },
  directives: {
    Ripple,
  },
  props: {
    fetchedSlot: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userData: getUserData(),
      sizeMappingOptions: [],
      selectedSizeMappingId: null,
      isCreatingSizeMapping: false,
      slotIdInput: '',
      defaultSizeInput: '',
      sizeMappingNameInput: '',
      sizeMappingMapInput: '',
      editSlot: null,
      editSizeMapping: null,
      slotCreated: null,
      sizeMappingState: null,
      sizeState: null,
      slotIdState: null,
      sizeMappingMappingsState: null,
      sizeMappingNameState: null,
      formSubmitAttempted: false,
    }
  },
  computed: {
    isFormValid() {
      return this.slotIdInput && this.defaultSizeInput && this.selectedSizeMappingId
    },
  },
  watch: {
    fetchedSlot(newValue) {
      if (newValue) {
        this.slotIdInput = newValue.slotId
        this.defaultSizeInput = newValue.defaultSize
        this.editSlot = newValue
      }
    },
  },
  methods: {
    isEmpty,
    onModalOpened() {
      if (this.fetchedSlot) {
        this.validateForm()
        this.slotIdInput = this.fetchedSlot.slotId
        this.defaultSizeInput = this.fetchedSlot.defaultSize
        this.editSlot = this.fetchedSlot
      }
      this.fetchSizeMappings()
    },
    validateForm() {
      const valid = this.$refs.form.checkValidity()
      let validate = true
      if (this.formSubmitAttempted === false) {
        validate = valid
        this.nameState = valid
      }
      this.sizeMappingState = this.selectedSizeMappingId !== null
      this.slotIdState = this.slotIdInput !== ''
      this.sizeState = this.defaultSizeInput !== '' && this.isValidJson(this.defaultSizeInput)
      validate = this.sizeMappingState && this.slotIdState && this.sizeState
      return validate
    },
    validateSizeMappingForm() {
      this.sizeMappingNameState = this.sizeMappingNameInput !== ''
      this.sizeMappingMappingsState = this.sizeMappingMapInput !== '' && this.isValidJson(this.sizeMappingMapInput)
      const validate = this.sizeMappingNameState && this.sizeMappingMappingsState
      return validate
    },
    fetchSizeMappings() {
      axios
        .post('', {
          query: `
            query {
              allSizeMappings(client:"${this.userData.profile.client.id}", orderBy:"name") {
                edges {
                  node {
                    id
                    name
                    mappings
                  }
                }
              }
            }
          `,
        })
        .then(res => {
          messageError(res, this)
          this.sizeMappingOptions = res.data.data.allSizeMappings.edges
          if (!this.editSlot) return

          const matchedElement = this.sizeMappingOptions.find(
            element => this.editSlot.sizeMapping.id === element.node.id,
          )

          if (matchedElement) {
            this.clickRow(matchedElement)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    createSizeMapping() {
      this.formSubmitAttempted = true
      if (!this.validateSizeMappingForm()) {
        return
      }
      const parsedMappings = JSON.parse(this.sizeMappingMapInput)
      const variables = { mappings: JSON.stringify(parsedMappings) }
      axios
        .post('', {
          query: `
            mutation($mappings:JSONString) {
              createSizeMapping(input: {
                client:"${this.userData.profile.client.id}",
                name: "${this.sizeMappingNameInput}",
                mappings: $mappings
              }) {
                sizeMapping {
                  id
                  name
                }
              }
            }
          `,
          variables,
        })
        .then(res => {
          const newMapping = res.data.data.createSizeMapping.sizeMapping
          this.selectedSizeMappingId = newMapping.id
          this.isCreatingSizeMapping = false
          this.sizeMappingNameInput = ''
          this.sizeMappingMapInput = ''
          this.fetchSizeMappings()
          showToast(this.$t('adsDisplay.sizeMappingCreated'), 1, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.sizeMappingCreatedError'), 2, this)
        })
    },
    changeSizeMapping() {
      this.formSubmitAttempted = true
      if (!this.validateSizeMappingForm()) {
        return
      }
      const parsedMappings = JSON.parse(this.sizeMappingMapInput)
      const variables = { mappings: JSON.stringify(parsedMappings) }
      axios
        .post('', {
          query: `
            mutation($mappings:JSONString) {
              updateSizeMapping(id:"${this.editSizeMapping.id}", input: {
                name: "${this.sizeMappingNameInput}",
                mappings: $mappings
              }) {
                sizeMapping {
                  id
                  name
                }
              }
            }
          `,
          variables,
        })
        .then(res => {
          messageError(res.error)
          this.editSizeMapping = null
          this.sizeMappingNameInput = ''
          this.sizeMappingMapInput = ''
          this.fetchSizeMappings()
          this.$emit('update-slots')
          showToast(this.$t('adsDisplay.sizeMappingEditSuccess'), 1, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.sizeMappingEditError'), 2, this)
        })
    },
    createSlot() {
      const parsedDefaultSize = JSON.parse(this.defaultSizeInput)
      const variables = { defaultSize: JSON.stringify(parsedDefaultSize) }

      axios
        .post('', {
          query: `
            mutation($defaultSize:JSONString) {
              createSlot(input: {
                client:"${this.userData.profile.client.id}",
                slotId: "${this.slotIdInput}",
                defaultSize: $defaultSize,
                sizeMapping: "${this.selectedSizeMappingId}"
              }) {
                slot {
                  id
                  slotId
                }
              }
            }
          `,
          variables,
        })
        .then(response => {
          this.newSlot = { slotId: '', defaultSize: '' }
          this.slotCreated = response.data.data.createSlot.slot
          showToast(this.$t('adsDisplay.slotCreated'), 1, this)
          this.onModalClosed(true)
          this.$emit('confirm-selection', this.slotCreated)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.slotCreatedError'), 2, this)
        })
    },
    changeSlot() {
      this.formSubmitAttempted = true
      if (!this.validateForm()) {
        return
      }
      const parsedDefaultSize = JSON.parse(this.defaultSizeInput)
      const variables = { defaultSize: JSON.stringify(parsedDefaultSize) }

      axios
        .post('', {
          query: `
            mutation($defaultSize:JSONString) {
              updateSlot(id:"${this.editSlot.id}", input: {
                slotId: "${this.slotIdInput}",
                defaultSize: $defaultSize,
                sizeMapping: "${this.selectedSizeMappingId}"
              }) {
                slot {
                  id
                  slotId
                }
              }
            }
          `,
          variables,
        })
        .then(response => {
          messageError(response.error)
          showToast(this.$t('adsDisplay.slotEditSuccess'), 1, this)
          this.$emit('update-slots')
          this.onModalClosed()
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.slotEditError'), 2, this)
        })
    },
    confirmSelection(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (this.editSlot) {
        this.changeSlot()
      } else {
        this.formSubmitAttempted = true
        if (!this.validateForm()) {
          return
        }
        this.createSlot()
      }
    },
    onModalClosed(changes = false) {
      this.editSlot = null
      this.slotIdInput = ''
      this.defaultSizeInput = ''
      this.selectedSizeMappingId = null
      this.sizeMappingOptions = []
      this.formSubmitAttempted = false
      this.isCreatingSizeMapping = false
      this.editSizeMapping = null
      this.sizeMappingNameInput = ''
      this.sizeMappingMapInput = ''
      this.sizeMappingState = null
      this.sizeState = null
      this.slotIdState = null
      this.sizeMappingMappingsState = null
      this.sizeMappingNameState = null
      if (!changes) this.$emit('modal-closed')
    },
    onChangeSizeMapping(sizeMapping) {
      this.editSizeMapping = sizeMapping
      this.sizeMappingNameInput = sizeMapping.name
      this.sizeMappingMapInput = sizeMapping.mappings
    },
    cancelEdit() {
      this.editSizeMapping = null
      this.sizeMappingNameInput = ''
      this.sizeMappingMapInput = ''
    },
    deleteSizeMapping(sizeMappingId) {
      axios
        .post('', {
          query: `
            mutation{
              deleteSizeMapping(id: "${sizeMappingId}") {
                found
                deletedId
              }
            }
          `,
        })
        .then(response => {
          this.fetchSizeMappings()
          if (response.data.data.deleteSizeMapping.found)showToast(this.$t('adsDisplay.sizeMappingDeleted'), 1, this)
          else showToast(this.$t('adsDisplay.sizeMappingDeletedError'), 2, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('adsDisplay.sizeMappingDeletedError'), 2, this)
        })
    },
    checkActive(id) {
      return this.selectedSizeMappingId === id
    },
    clickRow(valueSelected) {
      this.selectedSizeMappingId = valueSelected.node.id
    },
    isValidJson(jsonString) {
      try {
        const parsed = JSON.parse(jsonString)
        return typeof parsed === 'object' && parsed !== null
      } catch (error) {
        return false
      }
    },
  },
}
</script>
